import React from "react";
import LimitReachedSvg from "../../assets/images/popups/limit_reached.svg";
import { Link, Stack, Typography } from "@mui/material";
import PremiumPlanFearureSection from "./BasePopup";
const FreeAccessReachedLimitModal = () => {
  return (
    <>

      <h2 className="modal-title">
        Your free click has reached its limit
      </h2>

      <Typography id="modal-modal-description"> Upgrade plan for uninterrupted access to Kronicle Dealbase. </Typography>
      <div className="popup-check-list-container">
        <Stack className="popupWithCheckModalBody" direction={'row'}>
          <Stack style={{ width: "40%" }} alignItems={''} justifyContent={'center'}>
            <img src={LimitReachedSvg} alt={'Limit Reached'} style={{ width: '200px' }} />
          </Stack>
          <div style={{ width: "60%" }}>
            <PremiumPlanFearureSection />
            <Link
              href={`https://lexops.co/plans?token=${localStorage.getItem('token')}`} 
              target={'_blank'}>
              <button className="modal-primary-button"
                style={{ marginTop: '15px', maxWidth: '340px', marginLeft: '10px' }}>
                Buy Now
              </button>
            </Link>
          </div>
        </Stack>
      </div>

    </>
  );
};

export default FreeAccessReachedLimitModal;
